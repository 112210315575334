import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import AppServices from "../services/AppServices";
import Upload from "../assets/upload.svg";
import Delete from "../assets/delete.svg";

const Videotheque = () => {
  const [VideothequeType, setVideotechType] = useState(1);
  const [video, setVideo] = useState({
    video: "",
    title: "",
  });

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles.target.files[0];
    if (file) {
      axios
        .post(`${process.env.REACT_APP_UPLOAD_URL}`, file)
        .then((response) => {
          setVideo({
            ...video,
            video: response.data.url,
          });
        });
    }
  });

  const handleClick = () => {
    delete video._id;
    AppServices.post(
      {
        action: 9,
        data: video,
      },
      "/api"
    ).then((response) => {
      alert("Changements sauvgarder!");
    });
  };

  const handleChangeType = (e) => {
    // console.log(e.target.value);
    setVideotechType(e.target.value);
  };

  useEffect(() => {
    AppServices.post(
      {
        action: 10,
      },
      "/get"
    ).then((response) => {
      setVideo(response.data[0]);
    });
  }, []);

  return (
    <div className="page-container">
      <Sidebar current={5} />
      <div className="page-content">
        <Header />
        <div className="table-data" style={{ marginTop: 219 }}>
          <div className="popup-body popup-confirm">
            <Form className="form" id="formTop">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Video Youtube</Form.Label>
                <Form.Select
                  name="category"
                  className="form-control"
                  style={{ marginBottom: "2vh" }}
                  onChange={handleChangeType}
                  value={1}
                >
                  <option value={1}>Video</option>
                  <option value={2}>Image</option>
                </Form.Select>

                <Form.Control
                  type="text"
                  placeholder="Titre"
                  name="title"
                  style={{ marginBottom: "2vh" }}
                  value={video.title}
                  onChange={(e) => {
                    setVideo({
                      ...video,
                      title: e.target.value,
                    });
                  }}
                />
                {VideothequeType == 1 ? (
                  <Form.Control
                    type="text"
                    placeholder="URL"
                    name="video"
                    value={video.video}
                    onChange={(e) => {
                      setVideo({
                        ...video,
                        video: e.target.value,
                      });
                    }}
                  />
                ) : video.video == "" || video.video.includes("youtu") ? (
                  <label
                    className="drop-area form-control"
                    htmlFor="uploadImage"
                  >
                    <img src={Upload} alt="Upload" />
                    <input
                      accept="image/png,image/jpg,image/jpeg"
                      type="file"
                      style={{ display: "none" }}
                      id="uploadImage"
                      onChange={handleDrop}
                    />
                  </label>
                ) : (
                  <div className="avatar">
                    <img className="avatar-img" src={video.video} alt="image" />
                    <img
                      className="delete-icon"
                      src={Delete}
                      alt="Delete"
                      onClick={() => {
                        setVideo({
                          ...video,
                          video: "",
                        });
                      }}
                    />
                  </div>
                )}
                <div
                  className="next-button-service"
                  id="login"
                  style={{ width: "320px" }}
                  onClick={handleClick}
                >
                  Sauvegarder les changements
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videotheque;
