import React from 'react'

export default function StatisticItem({label,subLabel,state, index}) {
  return (
    <div className='s-card'>
        <div className='txt-s-card'>
            <span className='txt-1'>
                {label}
            </span>
            <span className='txt-2'>
                {subLabel}
            </span>
        </div>
        <div className='state'>
            {state}
        </div>
    </div>
  )
}
