import React, { useEffect, useState } from "react";
import Plus from "../../assets/awesome-plus.svg";
import Edit from "../../assets/awesome-edit.svg";
import Trash from "../../assets/trash.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppServices from "../../services/AppServices";

export default function TableService({ onAdd, popup }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const [services, setServices] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    AppServices.post(
      {
        action: 3,
      },
      "/get"
    ).then((response) => {
      setCategories(response.data);
    });
  }, []);

  const handleChange = (e) => {
    if (e.target.value === "-1") {
      AppServices.post(
        {
          action: 6,
        },
        "/get"
      ).then((response) => {
        setServices(response.data);
      });
    } else {
      AppServices.post(
        {
          action: 6,
          data: {
            category: e.target.value,
          },
        },
        "/get"
      ).then((response) => {
        setServices(response.data);
      });
    }
  };

  useEffect(() => {
    AppServices.post(
      {
        action: 6,
      },
      "/get"
    ).then((response) => {
      setServices(response.data);
    });
  }, [show, popup]);

  const handleDelete = () => {
    AppServices.post(
      {
        action: 5,
        data: {
          id: deleteId,
          active: false,
        },
      },
      "/api"
    ).then((response) => {
      // console.log(response);
      window.location = `/services`;
    });

    AppServices.post(
      {
        action: 6,
      },
      "/get"
    ).then((response) => {
      setServices(response.data);
    });
  };

  return (
    <div className="table-data">
      {" "}
      <Modal show={show} onHide={handleClose}>
        <div className="popup-body popup-confirm">
          <span id="span"> Are you sure you want to delete this item ?</span>
          <Form className="form">
            {/* Are you sure you want to delete this item */}
            <Button
              variant="primary bg-danger btn-delete"
              onClick={() => {
                handleDelete();
                setShow(false);
              }}
              id="login"
            >
              Supprimer
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="table-header">
        <div className="table-item">Service</div>
        <div className="table-item">Titre</div>
        <div className="table-item">Catégorie</div>
        <div className="table-item">Mode</div>
        <div className="table-item">
          <button className="button-add" onClick={() => onAdd()}>
            <img src={Plus} alt="plus" />
            <span>Ajouter</span>
          </button>
        </div>
      </div>
      <span style={{ marginRight: "10px" }}>Filtrer par catégorie: </span>
      <select className="select-categories" onChange={handleChange}>
        <option value={-1}>Toutes les catégories</option>
        {categories.map((val, i) => {
          return <option key={i} value={val._id}>{val.title["fr"]}</option>;
        })}
      </select>
      <div className="table-body" style={{ margin: "2vh 0px 5vh" }}>
        {services.map((val, i) => {
          return (
            <div className="table-body-item" key={val._id}>
              <div className="item">
                <img
                  className="table-avatar"
                  src={val.thumbs.length > 0 ? val.thumbs[0] : null}
                  alt="demo"
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="item">{val.title.fr}</div>
              <div className="item categoryTitle">{val.category?.title?.fr}</div>
              <div className="item reservationType">
                {val.use_reservation_email ? "Email" : "Lien de reservation"}
              </div>
              <div className="item">
                <button className="button-add" onClick={() => onAdd(val._id)}>
                  <img src={Edit} alt="plus" />
                  <span>Modifier</span>
                </button>
                <button
                  className="button-add"
                  onClick={() => handleShow(val._id)}
                >
                  <img src={Trash} alt="plus" />
                  <span>Supprimer</span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
