import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/Sidebar";
import "../globalcss/Global.css";
import Header from "../../components/header/Header";
import Statistics from "../../components/statistics/Statistics";
import Table from "../../components/table/Table";
import Form from "react-bootstrap/Form";
import Upload from "../../assets/upload.svg";
import Delete from "../../assets/delete.svg";
import axios from "axios";
import AppServices from "../../services/AppServices";
import { useGlobalState } from "../..";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Categories() {
  const [loading, setLoading] = useGlobalState("loading");
  const [maxServices, setMaxServices] = useState([]);
  const [show, setShow] = useGlobalState("show");
  const [data, setData] = useGlobalState("category");
  
  const notify = () => {
    toast.success(data.id ? "Category updated properly" : "Category added properly", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };
  
  const handleClose = () => {
    setShow(false);
    setData({
      title: {
        fr: "",
        en: "",
      },
      image: "",
      order: maxServices.length,
    });
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setData({
      ...data,
      title: {
        ...data.title,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleOrder = (e) => {
    setData({
      ...data,
      order: parseInt(e.target.value),
    });
  };

  const HandleClick = () => {
    AppServices.post(
      {
        action: Object.keys(data).includes("_id") ? 2 : 0,
        data: data,
      },
      "/api"
    ).then((response) => {
      // console.log(response);
      setData({
        title: {
          fr: "",
          en: "",
        },
        image: "",
        order: maxServices.length,
      });
      handleClose();
      notify();
    });
  };

  useEffect(() => {
    AppServices.post(
      {
        action: 9,
      },
      "/get"
    ).then((response) => {
      setMaxServices(response.data);
      setLoading(false);
    });
  }, [show]);

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles.target.files[0];
    if (file) {
      axios
        .post(`${process.env.REACT_APP_UPLOAD_URL}`, file)
        .then((response) => {
          setData({
            ...data,
            image: response.data.url,
          });
        });
    }
  });

  const [stats, setStats] = useState([]);

  useEffect(() => {
    setLoading(true);
    AppServices.post(
      {
        action: 3,
      },
      "/track"
    ).then((response) => {
      setStats([
        {
          label: "Total Categories",
          subLabel: "Active",
          state: response.cards_data[0],
        },
        {
          label: "Total Services",
          subLabel: "Active",
          state: response.cards_data[1],
        },
        {
          label: "Total Popups",
          subLabel: "Active",
          state: response.cards_data[2],
        },
        {
          label: "Total Actions",
          subLabel: "Tracking",
          state: response.tracking_count,
        },
      ]);
    });

    AppServices.post(
      {
        action: 9,
      },
      "/get"
    ).then((response) => {
      setMaxServices(response.data);
      setData({
        title: {
          fr: "",
          en: "",
        },
        image: "",
        order: response.data.length,
      });
      setLoading(false);
    });
  }, []);

  return (
    <div className="page-container">
      <Sidebar current={2} />
      <div className="page-content">
        <Header />
        <Statistics data={stats} />
        <Table onAdd={handleShow} />

        <Modal show={show} onHide={handleClose}>
          <div className="popup-body">
            <span id="span">Ajouter une Categorie</span>
            <Form className="form">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  type="text"
                  name="fr"
                  placeholder="Saisir.."
                  onChange={handleChange}
                  defaultValue={data.title.fr}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Titre (en)</Form.Label>
                <Form.Control
                  type="text"
                  name="en"
                  placeholder="Saisir.."
                  onChange={handleChange}
                  defaultValue={data.title.en}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Rang de la categories</Form.Label>
                <Form.Select name="category" onChange={handleOrder}>
                  {data.id ? (
                    maxServices.map((val, i) => {
                      return (
                        <option selected={i === data.order} value={i}>
                          {" "}
                          {i + 1}{" "}
                        </option>
                      );
                    })
                  ) : (
                    <option selected={true} value={data.order}>
                      {" "}
                      {data.order + 1}{" "}
                    </option>
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Image</Form.Label>

                {data.image === "" ? (
                  <label className="drop-area" htmlFor="uploadImage">
                    <img src={Upload} alt="Upload" />
                    <input
                      accept="image/png,image/jpg,image/jpeg"
                      type="file"
                      style={{ display: "none" }}
                      id="uploadImage"
                      onChange={handleDrop}
                    />
                  </label>
                ) : (
                  <div className="avatar">
                    <img className="avatar-img" src={data.image} alt="image" />
                    <img
                      className="delete-icon"
                      src={Delete}
                      alt="Delete"
                      onClick={() => {
                        setData({
                          ...data,
                          image: "",
                        });
                      }}
                    />
                  </div>
                )}
              </Form.Group>

              <Button variant="primary" id="login" onClick={HandleClick}>
                {data.id ? "Sauvegarder les changements" : "Ajouter"}
              </Button>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
}
