import React from 'react'
import LogoBlack from '../../assets/logoBlack.png'
import DashIcon from '../../assets/dashboard-icon.svg'
import {Link} from 'react-router-dom'
export default function Sidebar({current}) {
  return (
    <div className='sidebar-container'>
        <img src={LogoBlack} alt='' id="logo"/>

        <div className='menu-links '>
            <Link to={'/'} className={'link-item ' + (current === 1? 'active' : '')} >
                <img src={DashIcon} alt="" />
                <span>
                    Dashboard
                </span>
            </Link>
            <Link to={'/categories'} className={'link-item ' + (current === 2? 'active' : '')}>
                <img src={DashIcon} alt="" />
                <span>
                    Categories
                </span>
            </Link>
            <Link to={'/services'} className={'link-item ' + (current === 3? 'active' : '')}>
                <img src={DashIcon} alt="" />
                <span>
                    Services
                </span>
            </Link>
            <Link to={'/popup'} className={'link-item ' + (current === 4? 'active' : '')}>
                <img src={DashIcon} alt="" />
                <span>
                    Popup
                </span>
            </Link>
            <Link to={'/videotheque'} className={'link-item ' + (current === 5? 'active' : '')}>
                <img src={DashIcon} alt="" />
                <span>
                    Videotheque
                </span>
            </Link>
            <Link className={'link-item active'} onClick={
                () => {
                    localStorage.removeItem('token')
                    window.location = '/login'
                }
            }>
                {/* <img src={DashIcon} alt="" /> */}
                <span>
                    Logout
                </span>
            </Link>
        </div>
    </div>
  )
}
