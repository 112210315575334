import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Categories from "./pages/categories/Categories";
import Services from "./pages/services/Services";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboad/Dashboard";
import Popup from "./pages/popup/Popups";
import Loader from "./components/Loader";
import Videotheque from "./pages/Videotheque";

function App() {
  const ProtectAdmin = ({ children }) => {
    if (localStorage.getItem("token") !== (null && "" && undefined)) {
      return children;
    }

    return <Login />;
  };

  return (
    <BrowserRouter>
      <Loader />
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/"
          element={
            <ProtectAdmin>
              <Dashboard />
            </ProtectAdmin>
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectAdmin>
              <Categories />
            </ProtectAdmin>
          }
        />
        <Route
          path="/services"
          element={
            <ProtectAdmin>
              <Services />
            </ProtectAdmin>
          }
        />
        <Route
          path="/popup"
          element={
            <ProtectAdmin>
              <Popup />
            </ProtectAdmin>
          }
        />
        <Route
          path="/videotheque"
          element={
            <ProtectAdmin>
              <Videotheque />
            </ProtectAdmin>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
