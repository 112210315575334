import React, { useEffect, useState } from "react";
import Plus from "../../assets/awesome-plus.svg";
import Edit from "../../assets/awesome-edit.svg";
import Trash from "../../assets/trash.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppServices from "../../services/AppServices";
import { useGlobalState } from "../..";

const Table = ({ onAdd }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [showPopup, setShowPopup] = useGlobalState("show");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [category, setCategory] = useGlobalState("category");

  const handleShow = (e) => {
    setDeleteId(e);
    setShow(true);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!showPopup || !show) {
      AppServices.post(
        {
          action: 0,
        },
        "/stats"
      ).then((response) => [setData(response.data)]);
    }
  }, [show, showPopup]);

  const HandleDelete = (e) => {
    AppServices.post(
      {
        action: 2,
        data: {
          id: e.target.name,
          active: false,
        },
      },
      "/api"
    ).then((response) => {
      handleClose();
      window.location = `/categories`;
    });
  };

  const HandleEdit = (name) => {
    AppServices.post(
      {
        action: 3,
        data: {
          id: name,
        },
      },
      "/get"
    ).then((response) => {
      response.data[0].id = response.data[0]._id;
      delete response.data[0]._id;
      // console.log(response.data[0]);
      setCategory(response.data[0]);
    });
    setShowPopup(true);
  };

  return (
    <div className="table-data">
      {" "}
      <Modal show={show} onHide={handleClose}>
        <div className="popup-body popup-confirm">
          <span id="span"> Are you sure you want to delete this item ?</span>
          <Form className="form">
            {/* Are you sure you want to delete this item */}
            <Button
              variant="primary bg-danger btn-delete"
              id="login"
              name={deleteId}
              onClick={HandleDelete}
            >
              Supprimer
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="table-header">
        <div className="table-item">Catégorie</div>
        <div className="table-item">Titre</div>
        <div className="table-item">Nbr Services</div>
        <div className="table-item">
          <button className="button-add" onClick={onAdd}>
            <img src={Plus} alt="plus" />
            <span>Ajouter</span>
          </button>
        </div>
      </div>
      {data.map((category, i) => {
        return (
          <div className="table-body" key={i}>
            <div className="table-body-item">
              <div className="item">
                <img
                  className="table-avatar"
                  src={category.image}
                  alt="demo"
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="item">{category.title.fr}</div>
              <div className="item">{category.total_services}</div>
              <div className="item">
                <button
                  className="button-add"
                  onClick={() => HandleEdit(category._id)}
                >
                  <img src={Edit} alt="plus" />
                  <span>Modifier</span>
                </button>
                <button
                  className="button-add"
                  onClick={() => handleShow(category._id)}
                >
                  <img src={Trash} alt="plus" />
                  <span>Supprimer</span>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Table;
