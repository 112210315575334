import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LogoBlack from '../../assets/logoBlack.png'
import AppServices from "../../services/AppServices";
import "./Login.css";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: ""
  })

  const HandleClick = () => {
      AppServices.post({
        action: 1,
        data: data
    }, '/auth').then((response) => {
      Object.keys(response).map((val) => {
        localStorage.setItem(val, response[val])
        window.location = '/'
      })
    }).catch((error) => {
      alert("Please check you credentials")
    })
  }

  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
  }

  return (
    <div className="login-container">
      <div className="login-inputs">
        <img
          src={LogoBlack}
          alt="logo"
        />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChange}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" placeholder="Password"  onChange={handleChange}/>
          </Form.Group>

          <Button variant="primary" id="login" onClick={HandleClick}>
            Connexion
          </Button>
        </Form>
      </div>
      <div className="login-cover"></div>
    </div>
  );
}

export default Login