import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import "../globalcss/Global.css";
import ShapeOne from "../../assets/shape-one.svg";
import ShapeTwo from "../../assets/shape-two.svg";
import ShapeThree from "../../assets/shape-three.svg";
import Header from "../../components/header/Header";
import Statistics from "../../components/statistics/Statistics";
import Table from "../../components/table/Table";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import AppServices from "../../services/AppServices";
import { useGlobalState } from "../..";
import moment from "moment/moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  plugins: {
    title: {
      display: true,
      text: "",
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export default function Dashboard() {
  const [loading, setLoading] = useGlobalState("loading");
  const [headerStats, setHeaderStats] = useState([]);
  const types = [
    "total_click_from_client",
    "total_click_from_display",
    "total_visits_from_client",
    "total_visits_from_display",
  ];
  const [chartType, setChartType] = useState("total_click_from_client");
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Loading...",
        data: [],
        backgroundColor: "rgba(200, 200, 200)",
      },
    ],
  });

  const [data2, setData2] = useState({
    labels: [],
    datasets: [
      {
        label: "Loading...",
        data: [],
        backgroundColor: "rgba(200, 200, 200)",
      },
    ],
  });
  useEffect(() => {
    setLoading(true);
    AppServices.post(
      {
        action: 2,
      },
      "/track"
    ).then((response) => {
      const data = !response.data[0]
        ? {
            total_click_from_client: 0,
            total_visits_from_client: 0,
            total_click_from_display: 0,
            total_visits_from_display: 0,
          }
        : response.data[0];

      setHeaderStats([
        {
          label: "Réservations potentielles",
          subLabel: "Client",
          state: data.total_click_from_client,
        },
        {
          label: "Nb. visites",
          subLabel: "Client",
          state: data.total_visits_from_client,
        },
        {
          label: "Réservations potentielles",
          subLabel: "Display",
          state: data.total_click_from_display,
        },
        {
          label: "Nb. visites",
          subLabel: "Display",
          state: data.total_visits_from_display,
        },
      ]);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    AppServices.post(
      {
        action: 10,
        data: {
          key: "total_click_from_client",
        },
      },
      "/track"
    ).then((response) => {
      setData({
        ...data,
        labels: response.dates.map((date) => moment(date).format("DD-MM-YYYY")),
        datasets: response.data,
      });
    });

    AppServices.post(
      {
        action: 10,
        data: {
          key: "total_click_from_display",
        },
      },
      "/track"
    ).then((response) => {
      setData2({
        ...data2,
        labels: response.dates.map((date) => moment(date).format("DD-MM-YYYY")),
        datasets: response.data,
      });
      setLoading(false);
    });
  }, [chartType]);

  return (
    <div className="page-container">
      <Sidebar current={1} />
      <div className="page-content">
        <Header />
        <Statistics data={headerStats} />
        {/* <div className="chart-area" style={{ position: "relative" }}> */}
        <div className="chart-area">
          <h5 style={{ marginTop: "20px" }}>
            Lien Client : Suivi quotidien des réservations potentielles
          </h5>
          <Bar options={options} data={data} />
          <div style={{ marginBottom: "2vh" }}></div>
        </div>
        <div
          className="chart-area"
          // style={{ position: "relative", marginTop: "2vh" }}
          // style={{paddingBottom: 40 }}
        >
          <h5 style={{ marginTop: "20px" }}>
            Lien Display : Suivi quotidien des réservations potentielles
          </h5>
          <Bar options={options} data={data2} />
          <div style={{ marginBottom: "2vh" }}></div>
        </div>
      </div>
    </div>
  );
}
