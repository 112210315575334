import React,{useEffect, useState} from "react";
import Plus from "../../assets/awesome-plus.svg";
import Edit from '../../assets/awesome-edit.svg'
import Trash from '../../assets/trash.svg'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from '../../assets/logoBlack.png'
import AppServices from "../../services/AppServices";
import { useGlobalState } from "../..";

export default function TablePopup({onAdd, onEdit}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [deleteId, setDeleteId] = useState(null)
  const handleShow = (id) => {
    setDeleteId(id)
    setShow(true);
  }
  const [popup, setPopup] = useState([])
  const [loading, setLoading] = useGlobalState('loading')

  const handleDelete = () => {
      AppServices.post({
        action: 6,
        data: {
            id: deleteId,
            active: false,
            show: false
        }
      }, '/api').then((response) => {
        handleClose()
      })
  }

  useEffect(() => {
    
    AppServices.post({
        action: 7
    }, '/api').then((response) => {
        setPopup(response.data)
    })
  }, [show, onEdit])


  return (
    <div className="table-data"> <Modal show={show} onHide={handleClose}>
        <div className="popup-body popup-confirm">
          <span id="span"> Are you sure you want to delete this item ?</span>
          <Form className="form">
            {/* Are you sure you want to delete this item */}
            <Button variant="primary bg-danger btn-delete" id="login" onClick={handleDelete}>
              Supprimer
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="table-header">
        <div className="table-item">Popup</div>
        <div className="table-item">Type</div>
        <div className="table-item">
          <button className="button-add" onClick={() => onAdd()}>
            <img src={Plus} alt="plus" />
            <span>Ajouter</span>
          </button>
        </div>
      </div>
      {popup.map((val) => {
        return (
          <div className="table-body">
          <div className="table-body-item">
            <div className="item">
              <img className='table-avatar' src={Logo} alt="demo" />
            </div>
            <div className="item">{val.display ? "Display": "Client"}</div>
            <div className="item">
              <button className="button-add"  onClick={() => {onAdd(val._id)}}>
                <img src={Edit} alt="plus"/>
                <span>Modifier</span>
              </button>
              <button className="button-add" onClick={() => handleShow(val._id)}>
                <img src={Trash} alt="plus" />
                <span>Supprimer</span>
              </button>
            </div>
          </div>
        </div>
        )
      })}
    </div>
  );
}
