import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createGlobalState } from "react-hooks-global-state";
import { ToastContainer } from 'react-toastify';
import Loader from "./components/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));

const { setGlobalState, useGlobalState } = createGlobalState({
  show: false,
  category: {
    title: {
      fr: "",
      en: "",
    },
    image: "",
  },
  description: {
    en: "",
    fr: "",
  },
  loading: false,
});

root.render(
  <>
    <App />
    <ToastContainer />
  </>
);

export { setGlobalState, useGlobalState };
