import React from "react";
import StatisticItem from "./StatisticItem";

export default function Statistics({ data }) {
  return (
    <div className="statistics-container">
      {data.map((val, i) => {
        return <StatisticItem {...val} key={i} />;
      })}
    </div>
  );
}
