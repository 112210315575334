import React from "react";
import { useGlobalState } from "..";

const Loader = () => {
    const [loading, setLoading] = useGlobalState('loading')

    return (
        <>
            {loading ? <div className="loader-backdrop" style={{display: "block", zIndex: 10000}}>
                <div className="loader"></div>
            </div>: null}
        </>
        
    )
}
export default Loader